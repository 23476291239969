import React from 'react'

//MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

const AddWebinar = ({dialog, setDialog}) => {

    const handleCloseDialog = () => {
        setDialog(false)
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='md'
            open={dialog}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Añadir nuevo webinar</DialogTitle>
            <DialogContent>
                formulario
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddWebinar