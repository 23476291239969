import React, { useEffect, useRef, useState } from 'react'

//MUI
import {
    AppBar, Box, Button, Container, Dialog, FormControl, IconButton, Stack,
    TextField, Typography, Toolbar as MUIToolbar, Grid, useMediaQuery, Paper, InputBase, Divider, Tooltip,
    Fab,
    Menu,
    MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';

//DatePicker react-datepicker
// import DatePicker from 'react-datepicker';

//Datepicker MUI
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES } from '@mui/x-date-pickers/locales';

//Actions
import { basicTimerMsg } from '../../../actions/swal_msg';


// React-pdf-viewer
import { Viewer, Worker, LocalizationContext } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import es_ES from '@react-pdf-viewer/locales/lib/es_ES.json'

const init = {
    name: '',
    date: new Date(),
    image: '',
    file: '',
}

const initErr = {
    name_err: false,
};

const ProtocolModal = ({ openDialog, setOpenDialog, formValues, setFormValues }) => {

    //Close dialog
    const handleClose = () => {
        setOpenDialog(false)
    }

    //Media querys
    const matches = useMediaQuery('(max-width: 500px)')

    //Form values
    const [protocol, setProtocol] = useState(init)
    const { name, date, image, file } = protocol

    const [inputErr, setInputErr] = useState(initErr);
    const { name_err } = inputErr

    const name_ref = useRef();

    //on change file
    const changeHandler = ({ target }) => {
        if (target.files.length !== 0) {
            setProtocol(e => ({
                ...e,
                file: URL.createObjectURL(target.files[0])
            }))
        }
    };

    //delete file
    const deleteFile = () => {
        setProtocol(e => ({
            ...e,
            file: ''
        }));
        setAnchorEl(null);
    }


    //PDF-viewer
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar, renderDefaultToolbar } = toolbarPluginInstance;

    const TransformToolbarSlot = (slot) => ({
        ...slot,
        SwitchTheme: () => <></>,
    });

    const [l10n, setL10n] = useState(es_ES);
    const localizationContext = { l10n, setL10n };

    //on change image
    const handleChangeImg = ({ target }) => {
        if (target.files.length !== 0) {
            setProtocol(e => ({
                ...e,
                image: URL.createObjectURL(target.files[0])
            }));
        }
    };

    //delete image selected
    const deleteImg = () => {
        setProtocol(e => ({
            ...e,
            image: ''
        })
        );
    }

    const onChange = ({ target }) => {
        const name = target.name
        const value = target.value;
        setProtocol(e => ({
            ...e,
            [name]: value
        }))
        switch (name) {
            case 'name':
                setInputErr({
                    ...inputErr,
                    name_err: (value.length < 2 ||
                        value.length > 50) ? true : false
                });
                break;
            default:
                break;
        }
    }

    const formValidator = () => {
        const name_len = name.trim().length
        if (name_len > 1 && name_len <= 50) {
            if (date) {
                if (image !== '') {
                    if (file !== '') {
                        return true
                    }
                    else {
                        basicTimerMsg('Agregue el documento')
                    }
                }
                else {
                    basicTimerMsg('Agregue la imagen para el protocolo')
                }
            }
            else {
                basicTimerMsg('Ingrese una fecha valida')
            }
        }
        else {
            name_ref.current.select();
            setInputErr({
                ...inputErr,
                name_err: true
            });
        }
    }

    //Submit form
    const submit = (e) => {
        e.preventDefault();
        if (formValidator()) {
            alert('Guardar datos en la BD')
            setFormValues((prevFormValues) => [...prevFormValues, protocol]);
            setTimeout(() => {
                setProtocol(init)
            }, 2000)
        } else {
            console.log('Algo no anda bien')
        }
    };

    //inputs ref for open
    const inputRef = useRef();
    const fileInputRef = useRef();

    //Menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        localStorage.setItem("protocol", JSON.stringify(formValues));
    }, [formValues]);

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            fullScreen
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#283A49' }} elevation={0}>
                <MUIToolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Añadir nuevo protocolo
                    </Typography>
                </MUIToolbar>
            </AppBar>
            {/* <DialogTitle>Añadir nuevo protocolo</DialogTitle> */}
            <Container sx={{ my: 4 }} maxWidth='xxl'>

                <Grid container spacing={6}>
                    <Grid item xxl={4} xl={4} lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={3.5}>
                            <Grid item xl={12} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='name'
                                        value={name}
                                        onChange={onChange}
                                        error={name_err}
                                        helperText={name_err ? "Ingrese el titulo del protocolo" : ""}
                                        inputRef={name_ref}
                                        variant="outlined"
                                        label='Titulo/nombre de protocolo'
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xl={12} lg={6} md={6} sm={12} xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                                    <DatePicker
                                        label='Fecha de publicación'
                                        views={['year', 'month', 'day']}
                                        value={date}
                                        onChange={(newValue) => {
                                            setProtocol({ ...protocol, date: newValue });
                                        }}
                                        slotProps={{
                                            actionBar: { actions: ["today", "cancel", "accept"] },
                                            textField: { inputProps: { readOnly: true }, fullWidth: true },

                                        }}

                                    />
                                </LocalizationProvider>
                            </Grid>

                            {/* Upload image */}
                            <Grid item xl={12} lg={6} md={6} sm={12} xs={12}>
                                <Typography variant='h6' gutterBottom>Imagen de protocolo:</Typography>
                                <Box boxShadow='0 2px 4px 2px rgb(0 0 0 / 20%)' p={1} borderRadius={1}>
                                    {
                                        image !== ''
                                            ?
                                            <Box className='uploaded_image'>
                                                <img src={image} />
                                            </Box>
                                            :
                                            <Box sx={{ textAlign: 'center', backgroundColor: 'rgb(242, 242, 242)', px: 3, py: 8, borderRadius: 1 }} onClick={() => { inputRef.current.click(); }}>
                                                <CloudUploadIcon sx={{ fontSize: '5rem' }} />
                                                <Typography variant='body1' paragraph>Cargar imagen</Typography>
                                                <Typography variant='body1'>Sólo se aceptan imagenes con formato de tipo JPG, JPEG y PNG</Typography>
                                            </Box>
                                    }
                                    {
                                        image !== '' ?
                                            <Box display='flex' justifyContent='space-between' mt={1}>
                                                <Button sx={{ my: 'auto', textTransform: 'none' }} onClick={() => { inputRef.current.click() }}>Subir archivo</Button>
                                                <IconButton sx={{ my: 'auto', color: '#e74c3c' }} onClick={deleteImg}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                            :
                                            null
                                    }
                                    <input
                                        accept="image/jpeg, image/png, image/jpg"
                                        type="file"
                                        hidden
                                        onChange={handleChangeImg}
                                        ref={inputRef}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xxl={8} xl={8} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant='h5' gutterBottom>
                            Visualizar documento
                        </Typography>
                        <Box display='flex' justifyContent='flex-start' gap='10px' flexWrap='wrap' mb={2}>
                            <Box sx={{ my: 'auto' }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{ textTransform: 'none' }}
                                >
                                    Cargar archivo
                                    <input
                                        accept="application/pdf"
                                        type="file"
                                        hidden
                                        onChange={changeHandler}
                                        ref={fileInputRef}
                                    />
                                </Button>
                            </Box>
                            <Box sx={{ my: 'auto' }}>
                                <Tooltip title={file}>
                                    <Paper
                                        component="form"
                                        sx={{ display: 'flex', alignItems: 'center', width: matches ? '260px' : '300px', height: '38px', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;' }}
                                    >
                                        <InputBase
                                            sx={{ flex: 1, ml: 1 }}
                                            value={file !== '' ? `${file}` : 'Ningún archivo seleccionado'}
                                            disabled
                                        />
                                        {
                                            file !== ''
                                                ?
                                                <>
                                                    <Divider sx={{ height: 28, mr: 0, ml: 1.5 }} orientation="vertical" />
                                                    <IconButton sx={{ p: '10px' }} onClick={handleOpenMenu}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </>
                                                :
                                                null
                                        }
                                    </Paper>
                                </Tooltip>
                            </Box>
                            {/* Menu para eliminar archivo seleccionado */}
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                            >
                                <MenuItem onClick={deleteFile}>Eliminar documento seleccionado</MenuItem>
                            </Menu>
                        </Box>
                        {
                            file !== ''
                                ?
                                null
                                :
                                <>
                                    <Box className='upload_file_container' onClick={() => { fileInputRef.current.click() }}>
                                        <Box className='upload_file_content'>
                                            <UploadFileIcon sx={{ fontSize: '4rem', mb: 2 }} />
                                            <Typography variant='body1' paragraph>Carga un documento para visualizarlo en el siguiente recuadro</Typography>
                                            <Typography variant='body1'>Sólo se aceptan imagenes con formato de tipo PDF</Typography>
                                        </Box>
                                    </Box>
                                </>
                        }
                        {
                            file !== ''
                                ?
                                <Box sx={{ mb: 3 }}>
                                    <LocalizationContext.Provider value={localizationContext}>
                                        <div
                                            style={{
                                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '4px',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(TransformToolbarSlot)}</Toolbar>
                                                <IconButton sx={{ color: '#ec7063' }} onClick={deleteFile}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <div style={{ height: matches ? '500px' : '750px', width: '100%' }}>
                                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                    <Viewer fileUrl={file} plugins={[toolbarPluginInstance]} scrollMode='Page' />
                                                </Worker>
                                            </div>
                                        </div>
                                    </LocalizationContext.Provider>
                                </Box>
                                :
                                null
                        }
                    </Grid>
                </Grid>
            </Container>
            <Fab color='primary' sx={{ position: 'fixed', bottom: 15, right: 30 }} onClick={submit}>
                <NoteAddIcon />
            </Fab>
        </Dialog >
    )
}

export default ProtocolModal