import React, { useState, useEffect, useContext } from 'react';
import { format, parseISO } from 'date-fns';
import es from "date-fns/locale/es";
import { Link, useNavigate, useParams } from 'react-router-dom';

import '../Admin/Blog/blog.css';

import { AdminContext } from '../../context/AdminContext';

import { BLOG_VIEW } from '../../constants/admin/blog_constants';
import { GOOGLE_DRIVE_CDN } from '../../constants/main_constants';

//MUI
import {
    Box, Button, Card, CardActionArea, CardContent, CardMedia, Container,
    Grid, Tooltip, Typography
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

const Blog = () => {
    const navigate = useNavigate();
    const { data: { blog: userBlog } } = useContext(AdminContext);

    const { url } = useParams();

    // const matches = useMediaQuery('(max-width: 950px)');

    // blog visualizado
    const [item, setItem] = useState(BLOG_VIEW);
    const { title, content, publication_date, author, imgId } = item;
    // otros blogs
    const [items, setItems] = useState([]);

    // useEffect(() => {
    //     if (userBlog.length > 0) {
    //         const blog = userBlog.find(e => e.url === url);
    //         if (blog)
    //             setItem({
    //                 ...blog,
    //                 publication_date: `${
    //                     format(new Date(parseISO(blog.publication_date)), "d 'de' MMMM yyyy", { locale: es })
    //                 }`
    //             });
    //         else navigate('/blog');
    //     }
    // }, [url]);

    useEffect(() => {
        if (userBlog.length > 0) {
            setItems(shuffleArray(userBlog.filter(e => e.url !== url)));
            const blog = userBlog.find(e => e.url === url);
            if (blog)
                setItem({
                    ...blog,
                    publication_date: `${format(new Date(parseISO(blog.publication_date)), "d 'de' MMMM yyyy", { locale: es })
                        }`
                });
            else navigate('/blog');
        }
    }, [userBlog]);

    function shuffleArray(array) {
        let i = array.length - 1;
        for (; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        };
        return array.slice(0, 4);
    };

    const [copySuccess, setCopySuccess] = useState("Copiar");

    const handlecopyToClip = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopySuccess("¡Enlace copiado al portapapeles!");
        setTimeout(() => {
            setCopySuccess("Copiar");
        }, 2000);
    };

    return (
        <Container maxWidth="xl" sx={{ my: 7 }} className="last-view">
            <Grid container spacing={6}>
                <Grid item xl={8.5} lg={8.5} md={12} sm={12} xs={12}>
                    <Typography variant="h4" sx={{ fontWeight: '600' }} gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Última actualización: {publication_date}
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                        <img src={`${GOOGLE_DRIVE_CDN}${imgId}`} alt={'blog-NIN-Institute'} width="100%" />
                    </Box>
                    <Grid container spacing={2}>
                        {
                            content.map((e, i) =>
                                <Grid item xs={12} key={i}>
                                    <Grid container>
                                        {
                                            e.col.map(({ item, xs, md }, j) =>
                                                <Grid item xs={xs} md={md} key={j}>
                                                    <div className="myClass" dangerouslySetInnerHTML={{ __html: item }}></div>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>

                    <Box sx={{ my: 3 }}>
                        <Typography variant='body1'>
                            <b>Autor: </b>{author}
                        </Typography>
                    </Box>

                    <Tooltip title={copySuccess}>
                        <Button variant='contained' onClick={handlecopyToClip}
                            sx={{ textTransform: 'none', mb: 2 }}
                            endIcon={<ShareIcon />}
                        >
                            Compartir
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xl={3.5} lg={3.5} md={12} sm={12} xs={12}>
                    <Grid container spacing={4}>
                        {
                            items.map((e, index) => {
                                return (
                                    <Grid item xl={12} lg={12} md={6} sm={6} xs={12} key={index}>
                                        <Card sx={{ width: '100%' }}>
                                            <CardActionArea
                                                onClick={
                                                    () => {
                                                        navigate(`/blog/${e.url}`)
                                                        window.location.reload();
                                                    }
                                                }
                                            >
                                                <CardMedia
                                                    sx={{ height: 180 }}
                                                    image={`${GOOGLE_DRIVE_CDN}${e.imgId}`}
                                                    title={e.title}
                                                />
                                                <CardContent>
                                                    <Typography sx={{ fontSize: '18px', fontWeight: '600' }}
                                                        component="div" gutterBottom>
                                                        {e.title}
                                                    </Typography>
                                                    <Typography variant='body2' color='text.secondary'>
                                                        {`${e.preview}...`} <Link to={`/blog/${e.url}`}>Ver más</Link>
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Blog;