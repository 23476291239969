import React from 'react'

//img
import poster_11 from '../../../Assets/Resources/Webinars/portada_webinar_nov_13.png'

//Videos
import video_1 from '../../../Assets/Resources/Webinars/Adiposidad y Progresión de Daño Renal.mp4'
import video_2 from '../../../Assets/Resources/Webinars/Aplicabilidad de la Dieta de la Milpa en la Enfermedad Renal.mp4'
import video_3 from '../../../Assets/Resources/Webinars/MANEJO DE HIPERFOSFATEMIA EN LA ENFERMEDAD RENAL.mp4'
import video_4 from '../../../Assets/Resources/Webinars/Suplementación de vitaminas en la Enfermedad Renal Crónica.mp4'
import video_5 from '../../../Assets/Resources/Webinars/Suplementación nutricional, en el paciente con trasplante renal.mp4'
import video_6 from '../../../Assets/Resources/Webinars/Terapia medico nutricional en dialisis peritoneal.mp4'
import video_7 from '../../../Assets/Resources/Webinars/Un nuevo enfoque para el tratamiento de la enfermedad renal poliquística, terapia cetogénica centrada en plantas.mp4';
import video_8 from '../../../Assets/Resources/Webinars/24_marzo_terapia_medico_nutricional_en_el_adulto_mayor_con_erc.mp4';
import video_9 from '../../../Assets/Resources/Webinars/24_septiembre_nutricion_y_nefritis_lupica.mp4';
import video_10 from '../../../Assets/Resources/Webinars/Webinar Gratuito Octubre _Aspectos Genéticos de la Enfermedad Poliquística Renal_.mp4';
import video_11 from '../../../Assets/Resources/Webinars/Webinar gratuito Noviembre_ Estrategias Nutricionales basadas en Evidencia en el abordaje de Pacientes con PQR.mp4';

//MUI
import { Container, Grid, Typography } from '@mui/material'

const Webinars = () => {
    return (
        <Container maxWidth='xl' sx={{ my: 8 }}>
            <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                Webinars
            </Typography>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_1} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_2} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_3} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_7} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_5} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_6} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_4} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_8} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_9} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_10} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <video src={video_11} poster={poster_11} style={{ borderRadius: '8px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}
                        width="100%" height="auto" controls="controls" controlsList="nodownload"
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Webinars