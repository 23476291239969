import React, { useState } from 'react'

//MUI
import { AppBar, Card, CardContent, Container, Fab, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

//Components
import AddWebinar from './AddWebinar';

//Webinars prueba
import video_1 from '../../../Assets/Resources/Webinars/Adiposidad y Progresión de Daño Renal.mp4'
import video_2 from '../../../Assets/Resources/Webinars/Aplicabilidad de la Dieta de la Milpa en la Enfermedad Renal.mp4'
import video_3 from '../../../Assets/Resources/Webinars/MANEJO DE HIPERFOSFATEMIA EN LA ENFERMEDAD RENAL.mp4'
const webinars = [
    {
        id: 1,
        date: 'Septiembre 23, 2024',
        video: video_1
    },
    {
        id: 2,
        date: 'Octubre 22, 2024',
        video: video_2
    },
    {
        id: 3,
        date: 'Noviembre 10, 2024',
        video: video_3
    }
]

const CustomFab = styled(Fab)(({ theme }) => ({
    backgroundColor: '#283A49',
    '&:hover': {
        backgroundColor: '#405668',
    },
}));

const Webinars = () => {

    //Open menu
    const [anchorMenu, setAnchorMenu] = useState(null);
    const open = Boolean(anchorMenu);
    const handleOpenMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorMenu(null);
    };

    //Open dialog
    const [dialog, setDialog] = useState(false)

    const handleOpen = () => {
        setDialog(true)
    }

    return (
        <>
            <Container maxWidth='xl' sx={{ my: 5 }}>
                <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '28px' }} paragraph>Webinars publicados</Typography>
                <Grid container spacing={5}>
                    {
                        webinars.map((res) => (
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={res.id}>
                                <Card sx={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                                    <AppBar position="static" sx={{ bgcolor: '#fff' }} elevation={0}>
                                        <Toolbar>
                                            <Typography variant='body1' sx={{ flexGrow: 1, color: '#000', fontWeight: '600' }}>Webinar {res.date}</Typography>
                                            <IconButton onClick={handleOpenMenu}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Toolbar>
                                        <Menu
                                            anchorEl={anchorMenu}
                                            open={open}
                                            onClose={handleCloseMenu}
                                        >
                                            <MenuItem>Editar</MenuItem>
                                            <MenuItem>Eliminar</MenuItem>
                                        </Menu>
                                    </AppBar>
                                    <CardContent>
                                        <video src={res.video}
                                            width="100%" height="auto" controls="controls" controlsList="nodownload"
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>

            <CustomFab sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={handleOpen}>
                <AddIcon sx={{ color: '#fff' }} />
            </CustomFab>

            <AddWebinar dialog={dialog} setDialog={setDialog} />
        </>
    )
}

export default Webinars